import React from 'react'
import PropTypes from 'prop-types'
import GoTrue from 'gotrue-js'

const defaultState = {
  isLoggedIn: () => {},
  login: () => {},
  logout: () => {},
  acceptInvite: () => {},
  requestPasswordRecovery: () => {},
  recover: () => {},
  updatePassword: () => {},
}

const AuthContext = React.createContext(defaultState)

class AuthProvider extends React.Component {
  getAuthClient = () => {
    const { url } = this.props

    return new GoTrue({
      APIUrl: url,
      audience: ``,
      setCookie: false,
    })
  }

  isLoggedIn = () => this.getAuthClient().currentUser() !== null

  login = (email, password, successCallback, errorCallback) => {
    this.getAuthClient()
      .login(email, password, true)
      .then(() => successCallback())
      .catch(e =>
        errorCallback(
          e.json && e.json.error_description
            ? e.json.error_description
            : 'unknown error'
        )
      )
  }

  logout = callback => {
    this.getAuthClient().currentUser().logout().finally(callback)
  }

  acceptInvite = (token, password, successCallback, errorCallback) => {
    this.getAuthClient()
      .acceptInvite(token, password, true)
      .then(() => successCallback())
      .catch(e =>
        errorCallback(e.json && e.json.msg ? e.json.msg : 'unknown error')
      )
  }

  requestPasswordRecovery = (email, successCallback, errorCallback) => {
    this.getAuthClient()
      .requestPasswordRecovery(email)
      .then(() => successCallback())
      .catch(e =>
        errorCallback(e.json && e.json.msg ? e.json.msg : 'unknown error')
      )
  }

  recover = (token, successCallback, errorCallback) => {
    this.getAuthClient()
      .recover(token, true)
      .then(() => successCallback())
      .catch(e =>
        errorCallback(e.json && e.json.msg ? e.json.msg : 'unknown error')
      )
  }

  updatePassword = (password, successCallback, errorCallback) => {
    this.getAuthClient()
      .currentUser()
      .update({ password })
      .then(() => successCallback())
      .catch(e =>
        errorCallback(e.json && e.json.msg ? e.json.msg : 'unknown error')
      )
  }

  render() {
    const { children } = this.props

    return (
      <AuthContext.Provider
        value={{
          isLoggedIn: () => this.isLoggedIn(),
          login: (email, password, successCallback, errorCallback) =>
            this.login(email, password, successCallback, errorCallback),
          logout: callback => this.logout(callback),
          acceptInvite: (token, password, successCallback, errorCallback) =>
            this.acceptInvite(token, password, successCallback, errorCallback),
          requestPasswordRecovery: (email, successCallback, errorCallback) =>
            this.requestPasswordRecovery(email, successCallback, errorCallback),
          recover: (token, successCallback, errorCallback) =>
            this.recover(token, successCallback, errorCallback),
          updatePassword: (password, successCallback, errorCallback) =>
            this.updatePassword(password, successCallback, errorCallback),
        }}
      >
        {children}
      </AuthContext.Provider>
    )
  }
}

AuthProvider.propTypes = {
  url: PropTypes.string.isRequired,
}

export default AuthContext

export { AuthProvider }
