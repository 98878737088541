exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-recover-jsx": () => import("./../../../src/pages/recover.jsx" /* webpackChunkName: "component---src-pages-recover-jsx" */),
  "component---src-templates-search-jsx": () => import("./../../../src/templates/search.jsx" /* webpackChunkName: "component---src-templates-search-jsx" */),
  "component---src-templates-song-jsx": () => import("./../../../src/templates/song.jsx" /* webpackChunkName: "component---src-templates-song-jsx" */)
}

